import styled from "styled-components";

const Style = styled.div`
  .text-osearch {
    background-color: rgba(31, 31, 31) !important;
    border-color: rgb(68, 68, 68) !important;
    color: #fff !important ;
  }
`;

export default Style;
