import { Input } from "antd";

import Style from "./Style";

const RenderField = (item: any, formik: any) => {
  const nameField = formik.values[`${item.name}`];
  const nameFieldRequired = formik.values[`${item.name}=required`];
  // const errorField = formik.errors[`${item.name}`];

  switch (item.tag) {
    case "input":
      const { label, placeholder, className, width, margin } = item;
      return (
        <div>
          <div
            className={
              className
                ? className
                : "d-flex align-items-center justify-content-between"
            }
          >
            {label && (
              <label
                htmlFor="d-block"
                style={{ marginRight: "30px" }}
                className="text-label"
              >
                {label}
              </label>
            )}
            <Input
              type="text"
              className="text-osearch"
              id="text-husble"
              value={nameField}
              name={item.name}
              placeholder={placeholder}
              style={{
                width: width ? width : "65%",
                margin: margin ? margin : "0",
              }}
              onChange={(e) => {
                const { value } = e.target;
                formik.setFieldValue(item.name, value);
                item?.onChange && item?.onChange({ name: item.name, value });
              }}
            />
          </div>
        </div>
      );

    case "checkbox":
      return (
        <div>
          <div className="" style={{ display: "flex", margin: "0 5px" }}>
            <div
              className=""
              style={{
                minWidth: "20px",
                height: "20px",
                marginTop: "-10px",
                marginLeft: "5px",
              }}
            >
              <input
                id={item.name}
                type="checkbox"
                className=""
                value={nameField}
                name={item.name}
                placeholder={item.placeholder}
                onChange={() => {
                  const value = nameField === 1 ? true : 1;
                  formik.setFieldValue(item.name, value);
                  item?.onChange && item?.onChange({ name: item.name, value });
                }}
              />
            </div>
            <label
              style={{
                paddingLeft: "10px",
                cursor: "pointer",
              }}
              htmlFor={item.name}
              className="text-label"
            >
              {item.label}
              {item.required && "*"}
              {item.status && nameFieldRequired === true && (
                <span className="label-required">REQUIRED</span>
              )}
            </label>
          </div>
          {item.help && (
            <div
              style={{
                margin: "0 10px",
              }}
              className="help-text"
            >
              {item.help}
            </div>
          )}
        </div>
      );

    default:
      return null;
  }
};

const RenderForm = (item: any, formik: any): any => {
  return <Style>{RenderField(item, formik)}</Style>;
};

export default RenderForm;
