import { Grid } from "gridjs-react";
import { h } from "gridjs";
import { Table } from "antd";

import Style from "./Style";

interface Props {
  data: any;
  columns: any;
}

export default function Index({ data, columns }: Props) {
  return (
    <Style>
      <Table
        bordered
        columns={[
          ...columns,
          {
            title: "+",
            dataIndex: "+",
            width: 30,
            key: "+",
            fixed: "right",
          },
        ]}
        dataSource={data}
        scroll={{ x: 1500, y: 300 }}
      />
    </Style>
  );
}
