import styled from "styled-components";

const Style = styled.div`
  min-width: 255px;
  height: 100vh;
  color: #fff;

  border-right: 0.5px solid rgb(42, 42, 42);
`;

export default Style;
