import { useState } from "react";
import { useFormik } from "formik";
import { PlusCircleOutlined } from "@ant-design/icons";

import Drawer from "fragments/Modal/index";

import RenderForm from "fragments/Forms/index";
import Client from "apis/Client";

export default function New() {
  const [visible, setVisible] = useState(false);

  const nameField = {
    type: "input",
    tag: "input",
    default: "",
    value: "",
    col: "col-6",
    label: "Name",
    name: "name",
  };

  const uidField = {
    type: "input",
    tag: "input",
    default: "",
    value: "",
    col: "col-6",
    label: "Uid    ",
    name: "uid",
  };

  const primaryKeyField = {
    type: "input",
    tag: "input",
    default: "",
    value: "",
    col: "col-6",
    label: "Primary",
    name: "primaryKey",
  };

  const formik = useFormik({
    initialValues: { name: "", primaryKey: "" },
    onSubmit: async (values) => {
      console.log(values);
    },
  });

  return (
    <div>
      <Drawer
        visible={visible}
        title="Add new indexes"
        setVisible={() => setVisible(false)}
        onCreate={() => {
          Client.createIndexes(
            formik.values.name,
            formik.values.primaryKey
          ).then((data) => {
            console.log(data);
          });
        }}
      >
        <div className="my-2">{RenderForm(nameField, formik)}</div>
        <div className="my-2">{RenderForm(uidField, formik)}</div>
        <div className="my-2">{RenderForm(primaryKeyField, formik)}</div>
      </Drawer>
      <div
        className="d-flex justify-content-center mt-2 mb-3"
        onClick={() => setVisible(true)}
      >
        <div className="">
          <PlusCircleOutlined
            title="Add new indexes"
            style={{ fontSize: "22px" }}
          />
        </div>
      </div>
    </div>
  );
}
