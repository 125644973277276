import { Drawer, Button } from "antd";

import Style from "./Style";

interface Props {
  visible: boolean;
  setVisible: any;
  title: String;
  children: any;
  onCreate?: any;
}

export default function Index({
  visible,
  title,
  setVisible,
  children,
  onCreate,
}: Props) {
  return (
    <Style>
      <Drawer
        title={title}
        width={520}
        closable={false}
        onClose={setVisible}
        visible={visible}
        style={{
          backgroundColor: "rgb(31, 31, 31) !important",
          color: "#fff !important",
        }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            {onCreate && <Button onClick={onCreate}>Create</Button>}
          </div>
        }
      >
        {children}
        {/* <Button type="primary" onClick={this.showChildrenDrawer}>
          Two-level drawer
        </Button> */}
        {/* <Drawer
          title="Two-level Drawer"
          width={320}
          closable={false}
          onClose={this.onChildrenDrawerClose}
          visible={this.state.childrenDrawer}
        >
          This is two-level drawer
        </Drawer> */}
      </Drawer>
    </Style>
  );
}
