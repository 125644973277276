import styled from "styled-components";

const Style = styled.div`
  text-align: center;

  padding-bottom: 10px;
  border-bottom: 0.5px solid rgb(42, 42, 42);
  cursor: pointer;
`;

export default Style;
