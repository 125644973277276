import styled from "styled-components";

const Style = styled.div`
  body {
    padding: 0;
    margin: 0;
  }

  background-color: #181818;
  color: #fff;
  height: 100vh;

  .app {
    display: flex;
  }

  .ant-drawer-body {
    background-color: rgb(31, 31, 31) !important;
    color: #fff !important;
  }

  overflow-y: hidden;
`;

export default Style;
