import styled from "styled-components";

const Style = styled.div`
  width: 55px;
  height: 100vh;
  border-right: 0.5px solid rgb(42, 42, 42);
  color: rgb(238, 238, 238);
`;

export default Style;
