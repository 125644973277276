import styled from "styled-components";

const Style = styled.div`
  .ant-drawer-body {
    background-color: rgb(31, 31, 31) !important;
    color: #fff !important;
  }
`;

export default Style;
