import { MeiliSearch } from "meilisearch";

const client = new MeiliSearch({
  host: "http://127.0.0.1:7700",
  apiKey: "masterKey",
});

export default class Client {
  static getIndexes() {
    return client.listIndexes();
  }

  static getDocuments() {
    return client.index("movies").getDocuments({ limit: 200 });
  }

  static createIndexes(uid: string, primaryKey: string) {
    return client.createIndex(uid, { primaryKey });
  }
}
