export const IconDocment = () => {
  return (
    <svg
      className="m-auto text-color-inherit"
      width="22px"
      height="19px"
      viewBox="0 0 22 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "18px", height: "18px" }}
    >
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Artboard"
          transform="translate(-967.000000, -2846.000000)"
          stroke="currentColor"
          stroke-width="2"
        >
          <g id="table-editor" transform="translate(968.000000, 2847.000000)">
            <rect
              id="Rectangle"
              x="0"
              y="0"
              width="20"
              height="17"
              rx="2"
            ></rect>
            <line x1="0" y1="11" x2="21" y2="11" id="Path"></line>
            <line x1="0" y1="5.5" x2="21" y2="5.5" id="Path"></line>
            <line x1="7" y1="0" x2="7" y2="16" id="Path"></line>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const IconHome = () => {
  return (
    <svg
      className="m-auto text-color-inherit"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      style={{ width: "18px", height: "18px" }}
    >
      <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
      <polyline points="9 22 9 12 15 12 15 22"></polyline>
    </svg>
  );
};
