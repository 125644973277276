import React, { useState, useEffect } from "react";
import { DownOutlined } from "@ant-design/icons";

import Client from "apis/Client";

import Style from "./Style";

export default function Lists() {
  const [state, setState]: any = useState([]);

  useEffect(() => {
    Client.getIndexes().then((doc) => {
      console.log(doc, "Client");
      setState(doc);
    });
  }, []);

  return (
    <Style>
      <div className="list-indexes">
        {state.map((i: any) => (
          <div key={i.name} className="item-indexes">
            <div className=""> {i.name}</div>
            <div className="">
              <DownOutlined />
            </div>
          </div>
        ))}
      </div>
    </Style>
  );
}
