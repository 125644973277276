import React from "react";
import { useFormik } from "formik";

import RenderForm from "fragments/Forms";

export default function Index() {
  const searchField = {
    type: "input",
    tag: "input",
    default: "",
    value: "",
    col: "col-6",
    name: "name",
    placeholder: "Search ...",
    className: "d-flex align-items-center justify-content-center",
    width: "100%",
    margin: "0 20px",
  };

  const formik = useFormik({
    initialValues: {},
    onSubmit: async (values) => {
      console.log(values);
    },
  });
  return (
    <div>
      <div className="">{RenderForm(searchField, formik)}</div>
    </div>
  );
}
