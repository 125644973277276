import styled from "styled-components";

const Style = styled.div`
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px;

  border-bottom: 0.5px solid rgb(42, 42, 42);
`;

export default Style;
