import React from "react";

import Style from "./Style";

export default function index() {
  return (
    <Style>
      <img
        src="https://app.supabase.io/img/supabase-logo.svg"
        alt=""
        className=""
      />
    </Style>
  );
}
