import React from "react";

import { IconHome } from "fragments/Icons/index";

import Style from "./Style";

export default function index() {
  return (
    <Style>
      <IconHome />
    </Style>
  );
}
