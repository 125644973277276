import styled from "styled-components";

const Style = styled.div`
  img {
    width: 32px;
    height: 32px;
  }

  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px;
`;

export default Style;
