import React from "react";

import Pannel from "layouts/Pannel/index";
import Sidebar from "layouts/Sidebar/index";

import Logo from "components/Features/Logo/index";
import Home from "components/Features/Home/index";
import Navbar from "components/Features/Navbar/index";

import Title from "components/Documents/Title/index";
import Lists from "components/Documents/Lists/index";
import Source from "components/Documents/Source/index";
import New from "components/Documents/New/index";
import Search from "components/Documents/Search/index";

import Style from "./Style";

function App() {
  return (
    <Style>
      <header className="app">
        <Pannel>
          <Logo />
          <Home />
          <Navbar />
        </Pannel>
        <Sidebar>
          <Title />
          <New />
          <Search />
          <Lists />
        </Sidebar>
        <Source />
      </header>
    </Style>
  );
}

export default App;
