import styled from "styled-components";

const Style = styled.div`
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px;

  border-bottom: 0.5px solid rgb(42, 42, 42);

  .list-indexes {
    padding: 0 20px;
    text-align: left;
  }
  .item-indexes {
    padding: 7px 5px 7px 5px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 0.5px solid rgb(42, 42, 42);

    &:hover {
      background-color: rgb(33, 33, 33);
      border-radius: 2px;
      cursor: pointer;
    }
  }
`;

export default Style;
