import { useEffect, useState } from "react";
import { keys } from "lodash";

import Table from "fragments/Tables";

import Style from "./Style";
import Client from "apis/Client";

export default function Index() {
  const [state, setState]: any = useState([]);

  useEffect(() => {
    Client.getDocuments().then((doc) => {
      console.log(doc, "getDocuments");
      setState(doc);
    });
  }, []);

  const columns = keys(state[0]).map((i: any) => {
    return {
      title: i,
      dataIndex: i,
      width: 150,
      key: i,
    };
  });

  return (
    <Style>
      <div style={{ height: "50px" }} className="h">
        header
      </div>
      <Table columns={[...columns]} data={state} />
    </Style>
  );
}
